<template>
<div class="page-container">
  <Breadcrumb></Breadcrumb>
  <!--  操作区-->
  <el-card>
    <el-form
        :inline="true"
        :model="selectInfo"
        class="demo-form-inline"
        ref="selectOptionsFormRef"
    >
      <el-form-item label="患者姓名" prop="userName">
        <el-input
            v-model="selectInfo.userName"
            placeholder="请输入患者姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="医院名称" prop="hospitalId">
        <el-select
            v-model="selectInfo.hospitalId"
            placeholder="选择医院"
            filterable
        >
          <el-option label="全部" :value="''"></el-option>
          <el-option
              v-for="(item, index) in hospitalList"
              :key="index"
              :label="item.hospitalName"
              :value="item.hospitalId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="医生工作室" prop="groupId">
        <el-select
            v-model="selectInfo.groupId"
            placeholder="选择工作室"
            filterable
        >
          <el-option label="全部" :value="''"></el-option>
          <el-option
              v-for="(item, index) in doctorOfficeList"
              :key="index"
              :label="item.groupName"
              :value="item.groupId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联医生" prop="assistantId">
        <el-select
            v-model="selectInfo.assistantId"
            placeholder="选择工作室"
            filterable
        >
          <el-option label="全部" :value="''"></el-option>
          <el-option
              v-for="(item, index) in assistantList"
              :key="index"
              :label="item.assistantName"
              :value="item.assistantId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="resetSelectOptions"
        >重置</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
<!--  列表区-->
  <div class="form-region">
    <el-tabs @tab-click="handleClick" v-model="activeName" type="card">
      <el-tab-pane label="未测血压" name="1">
      </el-tab-pane>
      <el-tab-pane label="未测血糖" name="2">
      </el-tab-pane>
    </el-tabs>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 100%;margin-top: 0"
          size="mini"
          @sort-change="handlesortChange"
      >
        <el-table-column type="index" width="80" label="序号" key="1">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="患者姓名"
            width="120"
            align="center"
            key="2"
        >
        </el-table-column>
        <el-table-column
            prop="hospitalName"
            label="医院名称"
            align="center"
            width="280"
            key="3"
        >
        </el-table-column>
        <el-table-column
            prop="assistantName"
            label="医生工作室"
            align="center"
            width="150"
            key="4"
        >
        </el-table-column>
        <el-table-column
            prop="assistantName"
            label="关联医生"
            align="center"
            width="150"
            key="5"
        >
        </el-table-column>
        <el-table-column
            prop="managerDay"
            label="入组时长(天)"
            sortable="custom"
            align="center"
            width="140"
            key="6"
        >
          <template slot-scope="socpe">
            <span>{{ socpe.row.managerDay ? socpe.row.managerDay : 0 }}天</span>
          </template>
        </el-table-column>
        <el-table-column
            key="10"
            prop="day"
            label="测量天数"
            align="center"
        >
          <template slot-scope="socpe">
            <span>{{ socpe.row.day ? socpe.row.day : 0 }}/21</span>
          </template>
        </el-table-column>
        <el-table-column
            key="12"
            prop="processNum"
            label="联系次数"
            align="center"
        >
          <template slot-scope="socpe">
              <span>已联系{{
                  socpe.row.processNum
                }}次</span>
          </template>
        </el-table-column>
        <el-table-column align="center" key="13" label="操作" width="180">
          <template slot-scope="socpe">
              <el-button type="text" @click="showEventDialog(socpe.row.userId)">处理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <div class="pag_warpper">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="selectInfo.page"
        :page-sizes="[10, 20, 50]"
        :page-size="selectInfo.pageSize"
        layout="slot, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin-left: 30px"
    >
          <span class="total"
          >共 {{ total }} 条数据，共
            {{ Math.ceil(total / selectInfo.pageSize) }} 页</span
          >
    </el-pagination>
  </div>
  <el-dialog
      :visible.sync="visibleDialog"
      class="deal-events"
      @close="dealEventsDialog"
  >
      <span slot="title" class="show-title">
        <span>事件处理</span>
      </span>
    <div class="event-content">
      <div class="title ">
        <span>事件处理</span>
      </div>
      <div class="dealevent-box">
        <div class="event-content">
          <div class="left">
            <div class="row">
              <div class="item">
                <span>未测血压:</span>
                <span></span>
              </div>
              <div class="item miditem">
                <span>近3周血压测量情况:</span>
                <span>{{ deviceInfo.day || 0 }}/21(天)</span>
              </div>
              <div class="item">
                <span>测量周期:</span>
                <span>({{
                    deviceInfo.beginTime+'~'+deviceInfo.eventTime
                  }})</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span>未测血糖:</span>
                <span></span>
              </div>
              <div class="item miditem">
                <span>近3周血糖测量情况:</span>
                <span>{{ bloodInfo.day || 0 }}/21(天)</span>
              </div>
              <div class="item">
                <span>测量周期:</span>
                <span>({{
                    bloodInfo.beginTime+'~'+bloodInfo.eventTime
                  }})</span>
              </div>
            </div>
          </div>
        </div>
        <div class="phone-records">
          <div class="title" v-for="(item, index) in phoneList" :key="index">
            <div>{{ item.name }} :{{ item.phone }}</div>
            <span @click="telNum(item.phone)" style="cursor: pointer">呼叫</span>
          </div>
        </div>
      </div>
      <div class="title ">
        <span>处理方式</span>
        <el-select v-model="dealInfo.processingMethod" placeholder="请选择">
          <el-option
              v-for="item in methodOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="title ">
        <span>处理结果</span>
        <el-select v-model="dealInfo.processingResult" placeholder="请选择">
          <el-option
              v-for="item in resultOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="title" style="display: flex">
        <span style="width: 75px">备注</span>
        <el-input
            v-model="dealInfo.remarks"
            type="textarea"
            maxlength="200"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 10 }"
        ></el-input>
      </div>
      <!--      <div class="title ">-->
      <!--        <span>处理时间</span>-->
      <!--        <span class="dealtime">2012-12-12</span>-->
      <!--      </div>-->
      <div class="title ">
        <span>处理记录</span>
        <div class="dialogTable">
          <el-table
              :data="patientEventList"
              style="width: 100%;"
              height="250"
          >
            <el-table-column prop="processingTime" label="处理日期">
              <template slot-scope="scope">
                {{
                  scope.row.processingTime
                      ? timeMode(scope.row.processingTime).dateMin
                      : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="customerName" label="处理人">
            </el-table-column>
            <el-table-column prop="deviceEvent" label="处理事件">
              <template slot-scope="scope">
                  <span class="cellText">{{
                      scope.row.deviceEvent === 1 ? "血压事件" : ""
                    }}</span>
                <span class="cellText" v-if="scope.row.bloodEvent === 1"
                >,</span
                >
                <span class="cellText">{{
                    scope.row.bloodEvent === 1 ? "血糖事件" : ""
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="processing_method" label="处理方式">
              <template slot-scope="scope">
                  <span class="cellText">
                    {{
                      scope.row.processing_method === 1
                          ? "电话沟通"
                          : scope.row.processing_method === 2
                              ? "在线沟通"
                              : "待观察"
                    }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column prop="processing_result" label="处理结果">
              <template slot-scope="scope">
                  <span class="cellText">
                    {{
                      scope.row.processing_result === 0
                          ? "无法联系"
                          : "正常联系"
                    }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column prop="remarks" width="150" label="备注">
              <template slot-scope="scope">
                <el-popover
                    placement="top-start"
                    title="备注"
                    width="500"
                    trigger="hover"
                    :content="scope.row.remarks"
                >
                    <span slot="reference" class="marks-style">{{
                        scope.row.remarks | ellipsis
                      }}</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="btnBox">
        <span @click="visibleDialog = false">取消</span>
        <span @click="dealEventReq">确定</span>
      </div>
    </div>
  </el-dialog>

</div>
</template>

<script>
import {selectparams} from "../../mixins/selectparams";
import {mapState} from "vuex";
import {timeMode} from "../../tools/utils";
export default {
  name: "eventmanagement",
  mixins:[selectparams],
  data(){
    return{
      timeMode,
      selectInfo: {
        userName: "",
        groupId: "",
        hospitalId: "",
        assistantId: "",
        pageSize: 10,
        page: 1,
        sortType: '2',
        type: 1
      },
      //参数筛选列表

      activeName: "1",
      tableData:[],
      total:0,
      //事件处理相关
      visibleDialog:false,
      methodOptions: [
        {
          value: "1",
          label: "电话沟通 "
        },
      ],
      resultOptions: [
        {
          value: "0",
          label: "无法联系 "
        },
        {
          value: "1",
          label: "正常联系 "
        }
      ],
      bloodInfo: {
        eventTime: "",
        day: "",
        beginTime:""
      },
      deviceInfo: {
        eventTime: "",
        day: "",
        beginTime:""
      },
      concatInfo: {
        create_time: ""
      },
      patientEventList: [],
      bloodEvent: 0,
      deviceEvent: 0,
      dealInfo:{
        processingMethod: "1",
        processingResult: "1",
        remarks: "",
      },
      phoneList:[],
      currentUserId:''

    }
  },
  created() {
    this.getEventList()
  },
  computed:{
    ...mapState({
      isLogin:state => state.calling.isLogin,
    }),
  },
  methods:{
    handleClick() {
      //获取默认数据列表
      console.log(this.activeName)
      this.selectInfo.page = 1
      this.selectInfo.pageSize = 10
      this.getEventList()
    },
    onSubmit(){
      this.selectInfo.page = 1
      this.getEventList()
    },
    resetSelectOptions(){
      this.$refs.selectOptionsFormRef.resetFields();
      this.getEventList()
    },
    handleSizeChange(val){
      this.selectInfo.pageSize = val
      this.getEventList()
    },
    handleCurrentChange(val){
      this.selectInfo.page = val
      this.getEventList()
    },
    //关闭事件
    dealEventsDialog() {
     this.dealInfo =  {
        processingMethod: "1",
        processingResult: "1",
        remarks: "",
      }
    },
    dealEventReq(){
      let params = {
        ...this.dealInfo,
        userId:this.currentUserId,
        customerId:JSON.parse(sessionStorage.getItem('userInfo')).customerServerId,
        deviceEvent:this.deviceEvent,
        bloodEvent:this.bloodEvent,
      }
      console.log(params)
      if (this.dealInfo.remarks === "") {
        this.$message.warning("请填写备注!");
      } else {
        this.$http.dealPatientEvent(params)
            .then(res => {
              console.log(res);
              this.$message.success("事件处理成功!");
              this.visibleDialog = false;
              this.getEventList();
            })
            .catch(err => {
              this.$message.error(`事件处理失败!:${err.msg}`);
            });
      }
    },
    showEventDialog(userId){
      console.log(userId)
      this.currentUserId = userId
      this.$http.getEventProcessingInfo({userId}).then(res=>{
        console.log(res)
        this.patientEventList = res.result.data.patientEvent || []
        if (res.result.data.bloodInfo){
          this.bloodInfo.day =  res.result.data.bloodInfo.day
          this.bloodInfo.eventTime = res.result.data.bloodInfo.eventTime
          this.bloodInfo.beginTime = this.timeMode(Date.parse(new Date(res.result.data.bloodInfo.eventTime))-60*1000*60*24*7*3,'-').datestr
          this.bloodEvent = Object.keys(res.result.data.bloodInfo).length===0? 0 : 1
        }
        if (res.result.data.deviceInfo){
          this.deviceInfo.day = res.result.data.deviceInfo.day
          this.deviceInfo.eventTime = res.result.data.deviceInfo.eventTime
          this.deviceInfo.beginTime = this.timeMode(Date.parse(new Date(res.result.data.deviceInfo.eventTime))-60*1000*60*24*7*3,'-').datestr
          this.deviceEvent = Object.keys(res.result.data.deviceInfo).length===0? 0 : 1
        }

        this.visibleDialog = true;
      })
      this.$http.getPatientPhones({userId}).then(res=>{
        this.phoneList = res.result.data
      })
    },
    //排序
    handlesortChange(column){
      console.log(column)
      if (column.order === "ascending") {
        this.selectInfo.sortType='1'
        this.getEventList()
      }
      if (column.order === "descending") {
        this.selectInfo.sortType='2'
        this.getEventList()
      }
    },
    telNum(phone) {
      if (this.isLogin===1){
        ClinkAgent.previewOutcall({ tel: phone });
      }else {
        this.$message.warning('请先登录呼叫中心!')
      }
    },
    //获取事件列表
    getEventList(){
      let params = {
        ...this.selectInfo,
        customerServerId:JSON.parse(sessionStorage.getItem('userInfo')).customerServerId,
        type:Number(this.activeName)
      }
      this.$http.getEventListByType(params).then(res=>{
        this.tableData = res.result.data.list
        this.total = res.result.data.total
      })
    }
  }
}
</script>

<style scoped lang="less">
.form-region {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px;
  flex: 1;
  box-sizing: border-box;
  padding: 16px 20px;
}
.pag_warpper {
  background-color: #ffffff;
  height: 80px;
  padding: 20px;
  border-radius: 0 0 0 10px;
}

/deep/.deal-events {
  .el-dialog {
    width: 1000px;
    min-height: 275px;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
    margin-top: 20vh !important;
    .el-dialog__header {
      font-size: 18px;
      text-align: left;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      padding: 20px 20px 16px;
      border-bottom: 1px solid #ebeef5;
    }
    .el-dialog__body {
      padding: 16px 20px;
    }
  }
}
.event-content {
  text-align: left;
  font-family: PingFangSC-Medium, PingFang SC;
  /deep/.title {
    font-size: 14px;
    margin-bottom: 10px;
    span {
      font-weight: bold;
      color: #101b25;
    }
    .dealtime {
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 14px;
      font-weight: normal;
      color: #606266;
      margin-left: 8px;
    }
    .el-input {
      width: 240px;
      margin-left: 8px;
      .el-input__inner {
        width: 240px;
        height: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #101b25;
        border-radius: 2px;
        &::placeholder {
          color: rgba(181, 181, 181, 1);
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }
    }
    .el-select {
      .el-input__icon {
        line-height: 30px;
      }
      .el-icon-arrow-up:before {
        content: "";
      }
    }
  }
  .event-content {
    display: flex;
    margin-bottom: 8px;
    .left {
      margin-right: 26px;
      .row {
        display: flex;
        .miditem {
          width: 200px;
        }
        .item {
          margin-right: 22px;
          span:first-child {
            margin-right: 8px;
            line-height: 32px;
            color: black;
          }
        }
      }
    }
  }
  ._title {
    margin: 24px 0 16px 0;
    font-weight: bold;
    color: #101b25;
  }
  .subtitle {
    font-family: PingFangSC-Regular, PingFang SC;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    span:first-child {
      font-size: 14px;
      color: #ff595a;
    }
    span:last-child {
      font-size: 12px;
      color: #8193a3;
    }
  }
  .marks-style {
    font-size: 14px;
    color: #101b25 !important;
    font-weight: normal !important;
  }
  .btnBox {
    margin-top: 16px;
    display: flex;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      width: 76px;
      height: 32px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
    span:first-child {
      color: #8193a3;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-right: 16px;
    }
    span:last-child {
      background: #2c89dc;
      color: #ffffff;
    }
  }
  .system-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  /deep/ .el-radio__inner {
    border-radius: 2px !important;
  }

  /deep/ .el-radio__input.is-checked .el-radio__inner::after {
    content: "";
    width: 8px;
    height: 3px;
    border: 1px solid white;
    border-top: transparent;
    border-right: transparent;
    text-align: center;
    display: block;
    position: absolute;
    top: 3px;
    left: 2px;
    transform: rotate(-45deg);
    border-radius: 0px;
    background: none;
  }
}
.dialogTable {
  box-sizing: border-box;
  padding-top: 7px;
  /deep/.el-table {
    .el-table__footer-wrapper,
    .el-table__header-wrapper {
      //padding-left: 8px;
    }
    .el-table__body-wrapper {
      .el-table__cell {
        padding: 10px 0;
        .cell {
          color: #203549;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
    }
    margin-top: 0;
    thead {
      tr {
        th {
          box-sizing: border-box;
          background: #f7f8fa;
          box-shadow: inset 0px -1px 0px 0px #ebedf0;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          line-height: 20px;
          height: 54px;
          color: #203549;
        }
      }
    }
  }
  //去掉最下面的那一条线
  .el-table::before {
    height: 0;
  }
  /deep/ .edit-btn {
    .el-button {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2c89dc;
    }
  }
}
.cellText {
  font-weight: normal !important;
}
.dealevent-box{
  display: flex;
  align-items: center;
  .phone-records{
    flex: 1;
    .title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
      text-align: right;
      display: flex;
      justify-content: space-between;
      padding: 8px;
      span {
        color: #409eff;
      }
      div:first-child{
        padding-left: 46px;
      }
    }
  }
}
</style>